<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ titlePage }} Pengeluaran</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-pengeluaran-inventory"
                      >Pengeluaran</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ titlePage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <div
                class=""
                v-if="
                  titlePage == 'Tambah' &&
                  checkAccess('inventory', 'c_goods_out')
                "
              >
                <button class="btn btn-save" type="submit" :disabled="isSubmit">
                  <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                  Simpan
                </button>
              </div>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">{{ titlePage }} Pengeluaran</div>
                <div class="sub-title">
                  {{
                    titlePage == "Tambah"
                      ? "Tambah informasi yang diperlukan"
                      : "Detail informasi pengeluaran barang"
                  }}
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-6" v-if="this.formData.session != ''">
                <div class="form-group">
                  <label>Nomor</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="this.formData.session"
                  />
                </div>
              </div>
              <div class="col-6" v-if="this.formData.session != ''">
                <div class="form-group">
                  <label>Nominal</label>
                  <input
                    type="text"
                    class="form-control text-right"
                    disabled
                    v-model="this.formData.nilai"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Gudang</label>
                  <Select2
                    :options="optionWerehouse"
                    placeholder="Pilih Gudang"
                    v-model="formData.id_gudang"
                    :disabled="titlePage == 'Detail'"
                    class="editable"
                    :class="{
                      'is-invalid': formError && formError.id_gudang,
                    }"
                    @change="formChange('id_gudang', $event)"
                    @select="formChange('id_gudang', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_gudang"
                  >
                    {{ formError.id_gudang }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="nama">Penanggung Jawab: </label>
                  <Select2
                    :options="optionPenanggungJawab"
                    placeholder="Pilih Penanggung Jawab"
                    v-model="formData.id_karyawan"
                    class="editable"
                    :class="{
                      'is-invalid': formError && formError.id_karyawan,
                    }"
                    @change="formChange('id_karyawan', $event)"
                    @select="formChange('id_karyawan', $event)"
                    :disabled="titlePage == 'Detail'"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="nama">Nama Barang : </label>
                  <Select2
                    :options="optionGoods"
                    placeholder="Pilih Nama Barang"
                    v-model="formData.kode_barang"
                    :disabled="!formData.id_gudang || titlePage == 'Detail'"
                    class="editable"
                    :class="{
                      'is-invalid': formError && formError.kode_barang,
                    }"
                    @change="formChange('kode_barang', $event)"
                    @select="formChange('kode_barang', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.kode_barang"
                  >
                    {{ formError.kode_barang }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="nama">Tanggal Keluar : </label>
                  <Datepicker
                    :autoApply="true"
                    :class="{
                      'is-invalid': formError && formError.tanggal_keluar,
                    }"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_keluar"
                    placeholder="Tanggal Keluar"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    @cleared="formChange('tanggal_keluar', $event)"
                    @update:modelValue="formChange('tanggal_keluar', $event)"
                    :disabled="titlePage == 'Detail'"
                  ></Datepicker>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Beban Pengeluaran</label>
                  <Select2
                    :options="optionBeban"
                    placeholder="Pilih Beban Pengeluaran"
                    v-model="formData.akun_beban"
                    :disabled="titlePage == 'Detail'"
                    class="editable"
                    :class="{
                      'is-invalid': formError && formError.akun_beban,
                    }"
                    :settings="{
                      templateResult: formatState,
                    }"
                    @change="formChange('akun_beban', $event)"
                    @select="formChange('akun_beban', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.akun_beban"
                  >
                    {{ formError.akun_beban }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="nama">Jumlah : </label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control text-right"
                      v-model="formData.qty"
                      v-maska="'#######################'"
                      @keyup="formChange('qty', $event)"
                      :class="{ 'is-invalid': formError && formError.qty }"
                      placeholder="Masukkan Jumlah"
                      :disabled="titlePage == 'Detail'"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">{{
                        formData.nama_satuan ? formData.nama_satuan : "Pcs"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-error" v-if="formError && formError.qty">
                  {{ formError.qty }}
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Project :</label>
                  <Select2
                    :disabled="titlePage == 'Detail'"
                    v-model="formData.kode_project"
                    :options="optionProject"
                    placeholder="Pilih Project"
                    :settings="{ allowClear: true }"
                    @change="formChange('kode_project', $event)"
                    @select="formChange('kode_project', $event)"
                    :class="{
                      'is-invalid': formError && formError.kode_project,
                    }"
                  />
                  <div class="form-error">
                    {{ formError.kode_project }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Item RAB :</label>
                  <Select2
                    :disabled="titlePage == 'Detail'"
                    v-model="formData.id_rab"
                    :options="optionRAB"
                    placeholder="Pilih Item RAB"
                    :settings="{
                      templateResult: formatRab,
                    }"
                    @change="formChange('id_rab')"
                    @select="formChange('id_rab')"
                    :class="{ 'is-invalid': formError && formError.id_rab }"
                  />
                  <div class="form-error">
                    {{ formError.id_rab }}
                  </div>
                </div>
              </div>
              
              <div class="col-6">
                <div class="form-group">
                  <label for="nama">Keterangan Barang : </label>
                  <textarea
                    v-model="formData.keterangan"
                    rows="3"
                    placeholder="Masukkan Keterangan Barang"
                    class="form-control"
                    @keyup="formChange('keterangan', $event)"
                    :class="{
                      'is-invalid': formError && formError.keterangan,
                    }"
                    :disabled="titlePage == 'Detail'"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";
import {
  checkModuleAccess,
  checkRules,
  cksClient,
  showAlert,
} from "../../../../helper";
import { get_KaryawanList } from "../../../../actions/karyawan";
import { get_GudangList } from "../../../../actions/company/gudang";
import {
  get_BarangByGudang,
  get_BarangPengeluaranDetail,
  post_BarangPengeluaranSave,
} from "../../../../actions/barang";
import { maska } from "maska";
import {
  get_AkunDefault,
  get_AkunList,
} from "../../../../actions/akun_perkiraan";
// import moment from "moment";
// import "moment/locale/id";

import $ from "jquery";
import { get_ProjectList } from '../../../../actions/project';
import { get_ProjectRabList } from '../../../../actions/project/rab';

// import TableLite from 'vue3-table-lite'
export default {
  name: "DetailOpname",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
  },

  directives: {
    maska,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      optionWerehouse: [],
      optionGoods: [],
      optionPenanggungJawab: [],
      optionBeban: [],
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
      titlePage: this.$route.params.id ? "Detail" : "Tambah",
      optionRAB: [],
      optionProject: [],
      formData: {
        session: this.$route.params.id ? this.$route.params.id : "",
        kode_barang: "",
        id_karyawan: "",
        qty: "",
        nama_satuan: "",
        tanggal_keluar: "",
        keterangan: "",
        id_gudang: "",
        akun_beban: "",
        nilai: 0,
        kode_project: "",
        id_rab: ""
      },
      formError: [],
      rules: {
        kode_barang: {
          required: true,
        },
        id_karyawan: {
          required: true,
        },
        qty: {
          required: true,
          max: 1,
        },
        tanggal_keluar: {
          required: true,
        },
        id_gudang: {
          required: true,
        },
        akun_beban: {
          required: true,
        },
        kode_project: {
          required: false,
        },
        id_rab: {
          required:  false,
        }
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    if (this.formData.session != "") {
      this.getData();
    }
    this.getDefaultAkun();
    this.getKaryawan();
    this.getGudang();
    this.getAkun();
    this.getProject();
  },

  methods: {
    getProject() {
      get_ProjectList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionProject = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionProject.push({
                id: element.kode,
                text: element.nama,
                id_divisi: element.id_divisi
              });
            }
          }
        }
      );
    },
    getItem() {
      this.optionRAB = [];
      if (this.formData.kode_project) {
        get_ProjectRabList(this.formData.kode_project, (res) => {
        const { list } = res;
        this.optionRAB = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionRAB.push({
              id: element.id,
              text: element.nama,
              sub_level: element.sub_level,
            });
          }
        }
      });
      } else {
        this.formData.id_rab = ''
      }
    },
    getData() {
      get_BarangPengeluaranDetail(
        {
          id: this.formData.session,
        },
        (res) => {
          const { data } = res;
          this.optionGoods.push({
            id: data.kode_barang,
            text: data.nama_barang,
          });
          this.formData.nama_satuan = data.satuan;
          this.formData.kode_barang = data.kode_barang;
          this.formData.kode_project = data.kode_project;
          this.formData.id_rab = data.id_rab;
          this.formData.id_gudang = data.id_gudang;
          this.formData.id_karyawan = data.id_karyawan;
          this.formData.keterangan = data.keterangan;
          this.formData.akun_beban = data.akun_beban;
          this.formData.qty = data.qty;
          this.formData.tanggal_keluar = data.tanggal_keluar;
          this.formData.nilai = this.formatMoney(data.nilai);
          if (data.kode_project) {
            this.getItem();
          }
        }
      );
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    //master
    getKaryawan() {
      this.optionPenanggungJawab = [];
      get_KaryawanList(
        {
          id_company: this.id_company,
          aktif: true,
        },
        (res) => {
          var { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionPenanggungJawab.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    getDefaultAkun() {
      var name_id = "akun_beban_pengeluaran_barang";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0) {
                this.formData.akun_beban = element;
              }
            }
          }
        }
      );
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    getAkun() {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "14,15,17,13",
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionBeban = data;
        }
      );
    },

    getGudang() {
      this.optionWerehouse = [];
      get_GudangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionWerehouse.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    getGoods() {
      this.optionGoods = [];
      get_BarangByGudang(
        {
          id_company: this.id_company,
          id_gudang: this.formData.id_gudang,
        },
        (res) => {
          var { list } = res;
          this.optionGoods = list;
          // for (const key in list) {
          //   if (Object.hasOwnProperty.call(list, key)) {
          //     const element = list[key];
          //     this.l
          //   }
          // }
        }
      );
    },

    editable() {
      this.validated = 2;
    },
    async formChange(key, val) {
      if (Object.hasOwnProperty.call(this.rules, key)) {
        this.rules[key].changed = true;
      }
      if (key == "kode_barang") {
        this.rules.qty.max = val.qty;
        this.formData.nama_satuan = val.satuan;
      }
      if (key == "id_gudang") {
        this.formData.kode_barang = "";
        this.getGoods();
      }

      if (key == "kode_project") {
        this.getItem();
        this.rules.id_rab.required = val.id ? true : false;
      }

      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    formatRab(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = "";
      if (state.type && state.no) {
        $state = $(
          '<div class="' +
            padding +
            '">' +
            state.text +
            '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
            state.no +
            "<div>" +
            state.type +
            "</div></div></div>"
        );
      } else {
        $state = $('<div class="' + padding + '">' + state.text + "</div>");
      }
      return $state;
    },
    postData() {
      this.isSubmit = true;
      post_BarangPengeluaranSave(
        this.formData,
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: "Pengeluaran barang berhasil dicatat",
            onSubmit: () => {
              this.$router.push({
                name: "DataInventorySpending",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataInventorySpending",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan mengeluarkan barang ini?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.value {
  font-size: 13px;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
